<template>
  <div>
    <NuxtLayout v-if="data" :data="data">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useProjectStore } from './stores/ProjectStore'
import { useBlogStore } from '~/stores/BlogStore'

const store = useBlogStore()
const projectStore = useProjectStore()

const { locale } = useI18n()

const { data } = await useAsyncGql('app', {
  // @ts-ignore
  locale,
  options: {
    initialCache: false
  }
})

if (data.value) {
  store.pushCategories(data?.value!.allCategories)
}

if (data.value) {
  projectStore.pushCategories(data?.value!.allProjectCategories)
}
</script>

<style lang="postcss">
.page-enter-active,
.page-leave-active {
  transition: opacity 200ms ease;
}

.page-enter-active {
  transition-delay: 200ms;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>

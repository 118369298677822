import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionArticles, LazySectionRelatedArticles, LazySectionContactForm, LazySectionImageGallery, LazySectionImageGalleryTwo, LazySectionLogos, LazySectionMedia, LazySectionVideo, LazySectionBody, LazySectionCta, LazySectionItemDetail, LazySectionNext, LazySectionServices, LazySectionText, LazySectionTitle, LazySectionTitleTwo, LazySectionClientNumbers, LazySectionClients, LazySectionFeatures, LazySectionHero, LazySectionHeroB1, LazySectionHeroItem, LazySectionHeroSimple, LazySectionHighlights, LazySectionJobs, LazySectionLocations, LazySectionMultiple, LazySectionPeople, LazySectionRoadmap, LazySectionSplit, LazySectionSplitOne, LazySectionSplitThree, LazySectionSplitTitle, LazySectionSplitTwo, LazySectionTestimonials, LazySectionWorldwide } from '#components'
const lazyGlobalComponents = [
  ["SectionArticles", LazySectionArticles],
["SectionRelatedArticles", LazySectionRelatedArticles],
["SectionContactForm", LazySectionContactForm],
["SectionImageGallery", LazySectionImageGallery],
["SectionImageGalleryTwo", LazySectionImageGalleryTwo],
["SectionLogos", LazySectionLogos],
["SectionMedia", LazySectionMedia],
["SectionVideo", LazySectionVideo],
["SectionBody", LazySectionBody],
["SectionCta", LazySectionCta],
["SectionItemDetail", LazySectionItemDetail],
["SectionNext", LazySectionNext],
["SectionServices", LazySectionServices],
["SectionText", LazySectionText],
["SectionTitle", LazySectionTitle],
["SectionTitleTwo", LazySectionTitleTwo],
["SectionClientNumbers", LazySectionClientNumbers],
["SectionClients", LazySectionClients],
["SectionFeatures", LazySectionFeatures],
["SectionHero", LazySectionHero],
["SectionHeroB1", LazySectionHeroB1],
["SectionHeroItem", LazySectionHeroItem],
["SectionHeroSimple", LazySectionHeroSimple],
["SectionHighlights", LazySectionHighlights],
["SectionJobs", LazySectionJobs],
["SectionLocations", LazySectionLocations],
["SectionMultiple", LazySectionMultiple],
["SectionPeople", LazySectionPeople],
["SectionRoadmap", LazySectionRoadmap],
["SectionSplit", LazySectionSplit],
["SectionSplitOne", LazySectionSplitOne],
["SectionSplitThree", LazySectionSplitThree],
["SectionSplitTitle", LazySectionSplitTitle],
["SectionSplitTwo", LazySectionSplitTwo],
["SectionTestimonials", LazySectionTestimonials],
["SectionWorldwide", LazySectionWorldwide],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

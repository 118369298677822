import { paths } from '~/lib/paths'
import { LocaleCode } from '~/types/locales'
import { ModelApiKey } from '~/types/models'

export default function () {
  const { locale } = useI18n()
  const localePath = useLocalePath()

  const localePathByType = (typename: string, slug: string) => {
    let path
    switch (typename) {
      case 'IndexRecord':
        path = localePath('/')
        break
      case 'PageRecord':
        path = localePath(`/${slug}`)
        break
      case 'ArticleRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Article].path}/${slug}`)
        break
      case 'JobRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.Job].path}/${slug}`)
        break
      default:
        return null
    }

    return path
  }

  return {
    localePathByType
  }
}

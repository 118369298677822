<template>
  <NuxtLink
    class="block w-fit text-center"
    :title="$t('logoLink')"
    :to="
      store.isSimpleMenu && store.simpleMenuReference
        ? localePathByType(store.simpleMenuReference.__typename, store.simpleMenuReference.slug)
        : localePath('/')
    "
  >
    <img :src="imageSource" alt="STI Logo" :width="64" :height="128" />
  </NuxtLink>
</template>

<script setup lang="ts">
import logo from '~/assets/images/logo.svg'
import logoTeal from '~/assets/images/logo-teal.svg'
import logoTextTeal from '~/assets/images/logo-text-teal.svg'
import logoText from '~/assets/images/logo-text.svg'
import logoWhite from '~/assets/images/logo-white.svg'
import logoTextWhite from '~/assets/images/logo-text-white.svg'
import { useAppStore } from '~/stores/AppStore'

const localePath = useLocalePath()
const store = useAppStore()
const { localePathByType } = useLocalePathByType()

type ColorVariant = 'teal' | 'white' | 'default'

const props = defineProps({
  color: {
    type: String as PropType<ColorVariant>,
    default: 'default'
  },
  withText: {
    type: Boolean,
    default: false
  }
})

const imageSource = computed(() => {
  switch (props.color) {
    case 'teal':
      return props.withText ? logoTextTeal : logoTeal
    case 'white':
      return props.withText ? logoTextWhite : logoWhite
    default:
      return props.withText ? logoText : logo
  }
})
</script>

export enum LocaleCode {
  EN = 'en',
  IT = 'it',
  ES = 'es',
  SL = 'sl'
}

export interface Locale {
  code: LocaleCode
  domain?: string
  file: string
  iso: string
  name: string
}

import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior(to, _from, savedPosition) {
    const nuxtApp = useNuxtApp()

    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise(resolve => {
        nuxtApp.hooks.hookOnce('page:finish', () => {
          setTimeout(() => {
            resolve(savedPosition)
          }, 500)
        })
      })
    }

    if (to.hash) {
      return new Promise(resolve => {
        const targetElement = document?.getElementById(to.hash.substring(1))
        if (targetElement) {
          const top = window.scrollY + targetElement.getBoundingClientRect()?.top
          setTimeout(() => {
            resolve({
              left: 0,
              top: top || 0
            })
          }, 500)
        }
      })
    }

    return new Promise(resolve => {
      nuxtApp.hooks.hook('page:finish', async () => {
        await nextTick()
        setTimeout(() => {
          resolve({ top: 0 })
        }, 500)
      })
    })
  }
}

<template>
  <svg
    aria-hidden="true"
    class="inline-block self-center overflow-hidden fill-current stroke-current stroke-0"
    :class="[classSize]"
  >
    <use :xlink:href="`${symbolDefs}#icon-${name}`" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import symbolDefs from '~/assets/images/icons/symbol-defs.svg'

export type IconName =
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrow-down-left'
  | 'chevron-down-filled'
  | 'chevron-down'
  | 'chevron-left-filled'
  | 'chevron-right-filled'
  | 'chevron-up-filled'
  | 'chevron-up'
  | 'close'
  | 'facebook'
  | 'instagram'
  | 'twitter'
  | 'menu'
  | 'youtube'
  | 'polygon'
  | 'pin'
  | 'linkedin'
  | 'polygon-2'
  | 'globe'
  | 'minus'
  | 'plus'
  | 'alert'

export type IconSize = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'

interface Props {
  color?: string
  name: IconName
  size?: IconSize
}

const props = withDefaults(defineProps<Props>(), {
  color: 'black',
  size: 'md'
})

const classSize = computed(() => {
  let classList
  switch (props.size) {
    case '2xs':
      classList = 'w-3 h-3'
      break
    case 'xs':
      classList = 'w-4 h-4'
      break
    case 'sm':
      classList = 'w-5 h-5'
      break
    case 'lg':
      classList = 'w-7 h-7'
      break
    case 'xl':
      classList = 'w-10 h-10'
      break
    case '2xl':
      classList = 'w-14 h-14'
      break
    case '3xl':
      classList = 'w-16 h-16'
      break
    default:
      classList = 'w-6 h-6'
  }
  return classList
})
</script>

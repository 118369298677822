import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["app","articles","article","SectionBlocks","exposedArticles","exposedProjects","Page","Image","CallToAction","HeroBlocks","index","jobs","job","pages","page","projects","project"]}
export const GqlApp = (...params) => useGql()('app', ...params)
export const GqlArticle = (...params) => useGql()('article', ...params)
export const GqlArticles = (...params) => useGql()('articles', ...params)
export const GqlExposedArticles = (...params) => useGql()('exposedArticles', ...params)
export const GqlExposedProjects = (...params) => useGql()('exposedProjects', ...params)
export const GqlIndex = (...params) => useGql()('index', ...params)
export const GqlJob = (...params) => useGql()('job', ...params)
export const GqlJobs = (...params) => useGql()('jobs', ...params)
export const GqlPage = (...params) => useGql()('page', ...params)
export const GqlPages = (...params) => useGql()('pages', ...params)
export const GqlProject = (...params) => useGql()('project', ...params)
export const GqlProjects = (...params) => useGql()('projects', ...params)
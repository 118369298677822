import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.EN]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'news' },
    [ModelApiKey.Job]: { path: 'jobs' }
  },
  [LocaleCode.IT]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'news' },
    [ModelApiKey.Job]: { path: 'lavori' }
  },
  [LocaleCode.ES]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'noticias-e-informacion' },
    [ModelApiKey.Job]: { path: 'oportunidades-de-empleo' }
  },
  [LocaleCode.SL]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'novice' },
    [ModelApiKey.Job]: { path: 'moznosti-zaposlitve' }
  }
}

<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :key="key"
    :to="to ? to : undefined"
    :class="variant !== 'unstyled' && classList"
    @click="onClick"
  >
    <Icon
      v-if="icon && variant === 'primary'"
      :name="typeof icon === 'string' ? icon : icon.name"
      :size="iconSize"
      :color="typeof icon === 'string' ? 'white group-hover:black ' : icon.color"
    />
    {{ label }}
    <slot v-if="!label" />
    <span v-if="label === 'More' || label === 'Go'" class="sr-only">Details</span>
  </component>
</template>

<script setup lang="ts">
import type { IconSize, IconName } from '~/components/Icon.vue'
import { Theme, Size } from '~/types/button'

const NuxtLink = resolveComponent('NuxtLink')

type ButtonVariant = 'primary' | 'secondary' | 'link' | 'unstyled'

interface Props {
  disabled?: boolean
  icon?: {
    color?: string
    name: IconName
    size?: IconSize
  }
  variant?: ButtonVariant
  label?: string | null
  loading?: boolean
  to?: string | object | undefined | null
  prevent?: boolean
  size?: Size
  theme?: Theme
  filled?: boolean
  isTransparentBgNeutral?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  filled: false,
  isTransparentBgNeutral: false,
  variant: 'primary',
  loading: false,
  prevent: false,
  size: 'default',
  icon: () => {
    return {
      color: 'inherit',
      name: 'arrow-down-left'
    }
  }
})

const iconSize = computed(() => {
  switch (props.size) {
    case 'small':
      return 'lg'
    case 'medium':
      return 'xl'
    case 'large':
      return '2xl'
    default:
      return '3xl'
  }
})

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void
}>()

const $style = useCssModule()
const key = ref(0)

const classList = computed(() => {
  return [
    // Element
    $style.button,
    {
      // Style
      [$style.primary]: props.variant === 'primary',
      [$style.secondary]: props.variant === 'secondary',
      [$style.link]: props.variant === 'link',
      [$style.filled]: props.filled,
      [$style.transparentBgNeutral]: props.isTransparentBgNeutral,

      // Scale
      [$style.small]: props.size === 'small',
      [$style.medium]: props.size === 'medium',
      [$style.large]: props.size === 'large',

      // State
      [$style.disabled]: props.disabled || props.loading,

      // Mode
      [$style['on-dark']]: props.theme === 'dark'
    }
  ]
})

function onClick(event: MouseEvent) {
  if (props.disabled || props.loading || props.prevent) {
    event.preventDefault()
    event.stopPropagation()
  }
  emit('click', event)
}

onMounted(() => {
  key.value += 1
})
</script>

<style lang="postcss" module>
.button {
  --button-size: 104px;
  --border-color: theme(colors.neutral.800);
  --text-color: theme(colors.neutral.800);
  --background: transparent;
  --hover-background: theme(colors.neutral.800);
  -webkit-tap-highlight-color: transparent;
  --font-size: 'text-xl';
  background: linear-gradient(to left, var(--background) 50%, var(--hover-background) 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;

  &:hover {
    --text-color: white;
    background-position: left;
  }

  @apply relative inline-flex h-[var(--button-size)] cursor-pointer items-center justify-center gap-2 whitespace-nowrap rounded-[80px] border border-[var(--border-color)] bg-transparent text-xl font-black uppercase leading-none text-[var(--text-color)] no-underline;
}

/* Primary */
.primary {
  @apply pl-4 pr-6;

  &.medium {
    @apply pl-3 pr-5;
  }

  &.small {
    @apply pl-3 pr-4;
  }
}

/* Secondary */

.secondary {
  --button-size: 56px;
  @apply px-6;

  &.large {
    --button-size: 48px;
  }

  &.medium {
    --button-size: 40px;
    @apply px-5;
  }

  &.small {
    --button-size: 36px;
    @apply px-4;
  }
}

.filled {
  --border-color: theme(colors.neutral.900);
  --background: theme(colors.neutral.900);
  --hover-background: transparent;

  &.on-dark {
    &:hover {
      --border-color: theme(colors.neutral.800);
      --text-color: theme(colors.neutral.800);
    }
  }
}

.link {
  @apply border-0 uppercase underline transition-all duration-300 hover:text-teal-500;
}

/* State */
.disabled {
  --background: theme(colors.disabled);
  @apply pointer-events-none cursor-default border-disabled text-white;
}

/* SCALE */

.small {
  --button-size: 48px;
  @apply text-sm;
}

.medium {
  --button-size: 64px;
  @apply text-base;
}

.large {
  --button-size: 80px;
  @apply text-lg;
}

/* MODES */

.on-dark {
  --border-color: white;
  --text-color: white;

  &:hover {
    --border-color: theme(colors.neutral.800);
  }
}

.transparentBgNeutral {
  --hover-background: white;

  &:hover {
    --text-color: theme(colors.neutral.800);
  }
}
</style>

import { defineStore } from 'pinia'

export const useAppStore = defineStore('AppStore', {
  state: () => ({
    isAppEntered: false,
    isAppLoading: false,
    lockSmoothScroll: false,
    isSimpleMenu: false,
    simpleMenuCta: undefined,
    simpleMenuReference: undefined
  }),
  actions: {
    setAppEntered(bool: boolean) {
      this.isAppEntered = bool
    },
    setAppLoading(bool: boolean) {
      this.isAppLoading = bool
    },
    setLockSmoothScroll(bool: boolean) {
      this.lockSmoothScroll = bool
    },
    setSimpleMenu(bool: boolean) {
      this.isSimpleMenu = bool
    },
    setSimpleMenuCta(object: any) {
      this.simpleMenuCta = object
    },
    setSimpleMenuReference(object: any) {
      this.simpleMenuReference = object
    }
  }
})

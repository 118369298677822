import { default as _91slug_93RPYB4NvKKjMeta } from "/opt/build/repo/pages/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91slug_93vJrN4tLdlNMeta } from "/opt/build/repo/pages/jobs/[slug].vue?macro=true";
import { default as _91slug_93T6Ht95H20AMeta } from "/opt/build/repo/pages/news/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___en",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/:slug()",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___it",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/it/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___es",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/es/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RPYB4NvKKjMeta?.name ?? "slug___sl",
    path: _91slug_93RPYB4NvKKjMeta?.path ?? "/sl/:slug",
    meta: _91slug_93RPYB4NvKKjMeta || {},
    alias: _91slug_93RPYB4NvKKjMeta?.alias || [],
    redirect: _91slug_93RPYB4NvKKjMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___it",
    path: indexlaFeZuYZkUMeta?.path ?? "/it",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___es",
    path: indexlaFeZuYZkUMeta?.path ?? "/es",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___sl",
    path: indexlaFeZuYZkUMeta?.path ?? "/sl",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vJrN4tLdlNMeta?.name ?? "jobs-slug___en",
    path: _91slug_93vJrN4tLdlNMeta?.path ?? "/jobs/:slug()",
    meta: _91slug_93vJrN4tLdlNMeta || {},
    alias: _91slug_93vJrN4tLdlNMeta?.alias || [],
    redirect: _91slug_93vJrN4tLdlNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vJrN4tLdlNMeta?.name ?? "jobs-slug___it",
    path: _91slug_93vJrN4tLdlNMeta?.path ?? "/it/lavori/:slug",
    meta: _91slug_93vJrN4tLdlNMeta || {},
    alias: _91slug_93vJrN4tLdlNMeta?.alias || [],
    redirect: _91slug_93vJrN4tLdlNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vJrN4tLdlNMeta?.name ?? "jobs-slug___es",
    path: _91slug_93vJrN4tLdlNMeta?.path ?? "/es/oportunidades-de-empleo/:slug",
    meta: _91slug_93vJrN4tLdlNMeta || {},
    alias: _91slug_93vJrN4tLdlNMeta?.alias || [],
    redirect: _91slug_93vJrN4tLdlNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vJrN4tLdlNMeta?.name ?? "jobs-slug___sl",
    path: _91slug_93vJrN4tLdlNMeta?.path ?? "/sl/moznosti-zaposlitve/:slug",
    meta: _91slug_93vJrN4tLdlNMeta || {},
    alias: _91slug_93vJrN4tLdlNMeta?.alias || [],
    redirect: _91slug_93vJrN4tLdlNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___en",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/news/:slug()",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___it",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/it/news/:slug",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___es",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/es/noticias-e-informacion/:slug",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93T6Ht95H20AMeta?.name ?? "news-slug___sl",
    path: _91slug_93T6Ht95H20AMeta?.path ?? "/sl/novice/:slug",
    meta: _91slug_93T6Ht95H20AMeta || {},
    alias: _91slug_93T6Ht95H20AMeta?.alias || [],
    redirect: _91slug_93T6Ht95H20AMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/news/[slug].vue").then(m => m.default || m)
  }
]